.button {
  @include typo-input;
  padding: var(--space-xs) var(--space-s);
  background: var(--color-brown);
  color: var(--color-black);
  border: none;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;

  &:hover,
  &:focus {
    color: var(--color-grey);
    background: var(--color-gold);
  }
}
