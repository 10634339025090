.collection-list {
  @include mq(m) {
    margin-left: calc(var(--space-l) * -1);
    margin-right: calc(var(--space-l) * -1);
  }

  &__grid {
    position: relative;
    z-index: 2;
    @include get-grid;
    grid-auto-flow: row;
    grid-gap: 0;
    padding-bottom: var(--space-l);
  }

  &:not(:first-of-type) &__grid {
    margin-top: var(--space-m);
  }

  &__column {
    grid-column: span 6;
    display: grid;

    @include mq(m) {
      grid-column: var(--column);
      align-self: var(--align);
    }
  }

  &__spacer {
    width: 100%;

    @include mq(m) {
      width: var(--width);
      grid-column-end: span 12;
    }

    @include mq(l) {
      grid-column-end: span 6;
    }
  }

  &__item {
    width: 100%;
    justify-self: var(--alignment);
    grid-column-end: span 6;
    font-size: 1.5rem;
    position: relative;
    margin-bottom: var(--margin);
    padding: 0 var(--paddings);

    @include mq(m) {
      width: var(--width);
      grid-column-end: span 12;
    }

    @include mq(l) {
      grid-column-end: span 6;
    }

    &-content {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      justify-content: flex-end;
      background: rgba(0, 0, 0, 0.15);
      color: var(--color-grey);
      transition: opacity 0.2s ease-in-out;
      padding: var(--space-m);
    }

    a {
      display: block;
      width: 100%;
      background: var(--background);

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    a:hover &-content {
      opacity: 0.7;
    }

    &[style*="--ratio"] a {
      height: 0;
      overflow: hidden;
      padding-bottom: var(--ratio);
      position: relative;

      & > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &-headline {
      margin: 0;
      @include typo-teaser-headline;
    }

    &-description {
      margin: var(--space-xs) 0 0;
      @include typo-text;
    }
  }
}
