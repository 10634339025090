.intro-video {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  video {
    width: 100%;
    max-width: var(--video-max-width);
    height: 100%;
    position: relative;
    z-index: 1;
    object-fit: var(--video-position);
  }

  img {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    object-fit: cover;
    opacity: var(--image-opacity);
    width: 100%;
    height: 100%;
  }

  &__mute {
    position: absolute;
    z-index: 99;
    bottom: var(--space-m);
    right: var(--space-m);
    cursor: pointer;
    outline: none;
    border: none;
    background: rgba(0, 0, 0, 0.5);

    svg {
      color: #fff;
      width: var(--space-m);
      height: var(--space-m);
    }

    svg.mute {
      display: none;
    }

    svg.unmute {
      display: block;
    }

    &.muted {
      svg.mute {
        display: block;
      }

      svg.unmute {
        display: none;
      }
    }
  }

  &__scroll {
    position: absolute;
    top: var(--space-l);
    right: var(--space-l);
    margin-top: var(--space-l);
    z-index: 999;
    color: var(--color-gold);
    background: none;
    border: none;
    outline: none;
    appearance: none;
    opacity: 1;
    transition: opacity 0.2s 0.2s ease-in-out;
    cursor: pointer;
    display: none;
    @include typo-navigation;

    font-weight: 400;

    @include mq(m) {
      display: block;
    }

    &.hidden {
      opacity: 0;
      pointer-events: none;
    }
  }
}
