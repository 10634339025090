.scroll-project {
  margin: 0 auto;
  padding: 0;
}

.scroll-blocks {
  margin: var(--space-xl) 0 0;
}

.scroll-blocks__block {
  position: relative;
  overflow: hidden;
  z-index: 1;

  background: var(--background);
}

.scroll-image,
.scroll-image-sequence,
.scroll-video-sequence {
  height: 100vh;
  overflow: hidden;
  width: 100vw;
}

.scroll-image__container,
.scroll-image-sequence__container,
.scroll-video-sequence__container {
  width: 100%;
  height: 100%;
  position: relative;

  img,
  video {
    position: absolute;
    top: 10%;
    left: 0;
    width: 100%;
    height: 80%;
    object-fit: contain;
    @include get-outer-paddings;
  }

  video::-webkit-media-controls {
    display: none !important;
  }
}

.scroll-image-sequence {
  img {
    display: none;

    &.visible {
      display: block;
    }
  }
}
