@mixin get-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: var(--space-m);

  @include mq(m) {
    grid-template-columns: repeat(12, 1fr);
  }
}

@mixin get-outer-paddings {
  padding-left: var(--space-m);
  padding-right: var(--space-m);

  @include mq(m) {
    padding-left: var(--space-l);
    padding-right: var(--space-l);
  }
}
