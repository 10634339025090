@keyframes curtain {
  0% {
    transform: scaleX(0);
  }

  100% {
    transform: scaleX(1);
  }
}

.next-project {
  position: relative;
  display: block;
  display: flex;
  justify-content: flex-end;
  padding: 5px var(--space-l);
  text-transform: uppercase;

  &__line {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: var(--color-brown);
    width: 100%;
    transform-origin: left center;
    transform: scaleX(0);
  }

  &__label {
    color: var(--color-brown);
    @include typo-text;
  }

  &.animate &__line {
    animation: curtain 2.1s 0.4s ease-in forwards;
  }
}
