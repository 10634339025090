:root {
  --color-black: #000000;
  --color-grey: #dfdfdf;
  --color-mediumgrey: #a1a1a1;
  --color-darkgrey: #424242;
  --color-gold: #6b6538;
  --color-brown: #ac9d91;
  --color-yellow: #e3c13c;

  --space-xs: 5px;
  --space-s: 10px;
  --space-m: 20px;
  --space-l: 35px;
  --space-xl: 50px;
  --space-xxl: 80px;
  --space-xxxl: 120px;

  --border-size: 5px;

  @include mq(m) {
    --space-xs: 10px;
    --space-s: 20px;
    --space-m: 35px;
    --space-l: 50px;
    --space-xl: 80px;
    --space-xxl: 120px;
    --space-xxxl: 240px;

    --border-size: 8px;
  }
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: $font-family;
  background-color: var(--color-grey);
  color: var(--color-black);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  width: 100%;
  height: auto;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  display: inline-block;
}

a {
  text-decoration: none;
  color: inherit;
}

main {
  overflow: hidden;
  min-height: 55vh;
  max-width: 2400px;
  margin: 0 auto;
  padding-top: var(--space-xl);
  @include get-outer-paddings;
}

img.lazyload,
img.lazyloading {
  opacity: 0;
  transition: opacity 0.33s;
}

img.lazyloaded {
  transition: opacity 0.33s;
  opacity: 1;
}

img {
  display: inline-block;
}

.uniform__potty {
  position: absolute;
  left: -9999px;
}

label,
input,
textarea {
  display: block;
}

a[href*="google.de/maps"],
a[href*="maps.google.de"] {
  position: relative;
  padding-right: 30px;
}

a[href*="google.de/maps"]:after,
a[href*="maps.google.de"]:after {
  position: absolute;
  width: 25px;
  height: 25px;
  top: 0;
  right: 0;
  content: "";
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3Csvg id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25'%3E%3Ctitle%3EMap%3C/title%3E%3Cpath id='Map' d='M24.5,23.08a.51.51,0,0,1-.18,0l-8-3.08h0L7.67,23l-.16,0h0l-.16,0h0l0,0-7-3.13a.5.5,0,0,1-.3-.46V2.52a.5.5,0,0,1,.7-.46l6.82,3L16.33,2h0l.14,0h0l.12,0h0l.05,0,8,3.08a.5.5,0,0,1,.32.47.44.44,0,0,1,0,0c0,.57,0,12.45,0,17a.5.5,0,0,1-.5.5ZM17,19.16l7,2.59V6L17,3.23ZM8,6V21.8L16,19V3.2ZM1,19l6,2.68V6L1,3.29Z' fill='%230e1d25'/%3E%3C/svg%3E");
}

a[href*="google.de/maps"]:hover:after,
a[href*="maps.google.de"]:hover:after {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3Csvg id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25'%3E%3Ctitle%3EMap%3C/title%3E%3Cpath id='Map' d='M24.5,23.08a.51.51,0,0,1-.18,0l-8-3.08h0L7.67,23l-.16,0h0l-.16,0h0l0,0-7-3.13a.5.5,0,0,1-.3-.46V2.52a.5.5,0,0,1,.7-.46l6.82,3L16.33,2h0l.14,0h0l.12,0h0l.05,0,8,3.08a.5.5,0,0,1,.32.47.44.44,0,0,1,0,0c0,.57,0,12.45,0,17a.5.5,0,0,1-.5.5ZM17,19.16l7,2.59V6L17,3.23ZM8,6V21.8L16,19V3.2ZM1,19l6,2.68V6L1,3.29Z' fill='%23ac9d91'/%3E%3C/svg%3E");
}
