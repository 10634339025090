$font-family: "canada-type-gibson", Arial, Helvetica, sans-serif;

@mixin typo-navigation {
  font-family: $font-family;
  font-size: 20px;
  line-height: 1;
  font-weight: 200;
  text-transform: uppercase;

  @include mq(m) {
    font-size: 30px;
  }

  @include mq(xl) {
    font-size: 38px;
  }
}

@mixin typo-footer-navigation {
  font-family: $font-family;
  font-size: 38px;
  line-height: 1;
  font-weight: 200;
  text-transform: uppercase;
}

@mixin typo-headline {
  font-family: $font-family;
  font-size: 30px;
  line-height: 0.8;
  text-transform: uppercase;
  font-weight: 400;

  @include mq(xl) {
    font-size: 38px;
  }
}

@mixin typo-text {
  font-family: $font-family;
  font-size: 24px;
  line-height: 1.25;
  font-weight: 200;

  @include mq(xl) {
    font-size: 32px;
  }
}

@mixin typo-text-small {
  font-family: $font-family;
  font-size: 16px;
  line-height: 1.25;
  font-weight: 200;

  @include mq(xl) {
    font-size: 20px;
  }
}

@mixin typo-input {
  font-family: $font-family;
  font-size: 22px;
  line-height: 1;
  font-weight: 200;

  @include mq(xl) {
    font-size: 28px;
  }
}

@mixin typo-teaser-headline {
  font-family: $font-family;
  @include typo-text;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 0.8;
}
