.footer {
  &__back-wrapper {
    display: flex;
    justify-content: flex-end;
    @include get-outer-paddings;
  }

  &__back-to-top {
    border: none;
    outline: none;
    appearance: none;
    background: none;
    padding: var(--space-s) 0;
    color: var(--color-brown);
    opacity: 0;
    pointer-events: none;
    @include typo-navigation;

    &.is-visible {
      pointer-events: all;
      opacity: 1;
    }
  }

  &__wrapper {
    padding: var(--space-l) 0 var(--space-m);
    background: var(--color-darkgrey);
    display: flex;
    flex-direction: column;
    color: var(--color-brown);
    @include get-outer-paddings;
  }

  &__top {
    @include typo-footer-navigation;

    @include mq(m) {
      @include get-grid;
    }
  }

  &__logo {
    grid-column-end: span 3;

    svg {
      max-width: 200px;

      @include mq(m) {
        max-width: 300px;
      }
    }

    @include mq(m) {
      grid-column-end: span 6;
    }
  }

  &__navigation {
    grid-column-end: span 3;
    display: flex;
    flex-direction: column;
    margin-top: var(--space-l);

    @include mq(m) {
      margin-top: 0;
      justify-self: flex-end;
      align-items: flex-end;
      grid-column-end: span 6;
    }

    ul {
      display: block;
      margin-bottom: var(--space-s);

      @include mq(m) {
        text-align: right;
      }

      li {
        display: inline-block;
        margin-bottom: var(--space-xs);
      }

      li:not(:first-of-type):before {
        content: "—";
        margin: 0 0.5rem;
      }
    }

    a {
      border-bottom: var(--border-size) solid transparent;

      &.is-active {
        border-color: var(--color-yellow);
      }
    }
  }

  &__meta {
    display: flex;
    text-transform: uppercase;
    margin-top: var(--space-l);
    flex-direction: column;
    @include typo-text-small;

    @include mq(m) {
      flex-direction: row;
      justify-content: flex-end;
    }

    span {
      margin-right: var(--space-xs);
    }

    ul {
      margin: var(--space-s) 0 0;

      @include mq(m) {
        margin: 0;
      }

      li {
        &:not(:first-of-type) {
          &:before {
            content: "|";
            margin-left: var(--space-xs);
            margin-right: var(--space-xs);
          }
        }

        &:before {
          @include mq(m) {
            content: "|";
            margin-left: var(--space-xs);
            margin-right: var(--space-xs);
          }
        }
      }
    }
  }
}
