.grid {
  position: relative;
  @include get-grid;
  // @include mq(m) {
  //   padding-left: var(--space-l);
  //   padding-right: var(--space-l);
  // }
}

.grid:not(:first-of-type) {
  margin-top: var(--space-m);
}

.grid--has-background {
  padding: var(--space-xl) 0;
  background: var(--background);

  &::before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 100vw;
    background: var(--background);
    z-index: -1;
  }

  &:before {
    left: -50vw;
  }

  &:after {
    left: 50vw;
  }
}

.grid-column {
  grid-column: span 6;
  display: grid;
  grid-template-columns: 1fr;

  @include mq(m) {
    grid-column: var(--column);
    align-self: var(--align);
  }
}

.grid__headline {
  margin: 0;
  color: var(--color-brown);
  padding: 2px 0 0;
  @include typo-headline;
}

.grid__text {
  margin: var(--space-s) 0;
  @include typo-text;

  &--uppercase {
    text-transform: uppercase;
  }

  p {
    hyphens: auto;
    margin: 0 0 var(--space-s);
  }

  a:hover {
    color: var(--color-brown);
  }

  h2,
  h3,
  h4 {
    @include typo-headline;
  }
}

// Image
.grid__image {
  width: 100%;
  justify-self: var(--v-align);
  max-width: var(--max-width);

  @include mq(m) {
    padding-left: var(--m-left);
    padding-right: var(--m-right);
  }

  &-ratio {
    height: 0;
    overflow: hidden;
    position: relative;
    padding-bottom: var(--ratio);
    background: var(--background);
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.grid__video,
.grid__vimeo {
  width: 100%;
  justify-self: var(--v-align);
  max-width: var(--max-width);

  video,
  iframe {
    width: 100%;
    height: auto;
  }
}

.grid__vimeo {
  & > div {
    height: 0;
    overflow: hidden;
    position: relative;
    padding-bottom: 75%;

    & > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

// Social Media
.grid__social-media {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: var(--space-m) 0;

  @include mq(m) {
    justify-content: flex-end;
  }

  svg {
    width: var(--space-xl);
    height: var(--space-xl);
    fill: currentColor;
    flex-shrink: 0;

    @include mq(m) {
      width: var(--space-l);
      height: var(--space-l);
    }
  }

  a {
    display: flex;
    align-items: center;
    color: var(--color-brown);

    @include mq(m) {
      display: block;
      margin-left: var(--space-s);
      margin-bottom: var(--space-xs);
    }

    p {
      @include typo-text;
      font-weight: 400;
      margin-left: var(--space-s);

      @include mq(m) {
        display: none;
      }
    }

    &:hover,
    &:focus {
      color: var(--color-gold);
    }
  }
}

.grid__enumeration {
  h2,
  p {
    margin: 0;
    @include typo-text;
  }
}

.grid__enumeration-item {
  display: flex;
  flex-direction: column;
  margin: var(--space-s) 0;

  @include mq(l) {
    flex-direction: row;
  }

  h2 {
    &:after {
      content: ":";
    }

    @include mq(m) {
      width: calc(30% - 20px);
      padding-right: 20px;
      flex-grow: 1;
      white-space: nowrap;
    }

    @include mq(l) {
      width: calc(20% - 20px);
    }
  }

  p {
    @include mq(m) {
      width: 70%;
    }

    @include mq(l) {
      width: 80%;
    }
  }
}

.only-desktop {
  display: none;

  @include mq(m) {
    display: block;
  }
}

.only-mobile {
  display: block;

  @include mq(m) {
    display: none;
  }
}
