.contact-form {
  &__submit {
    @include mq(m) {
      display: flex;
      align-items: flex-start;

      button {
        margin-right: var(--space-s);
        flex-shrink: 0;
      }
    }
  }

  &__notice {
    padding: 0;
    margin: 0;

    &.error {
      color: red;
    }
  }
}
