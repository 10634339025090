.header {
  &__line {
    top: 0;
    left: 0;
    position: fixed;
    height: var(--border-size);
    width: 100%;
    background: var(--color-gold);
    z-index: 999;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 30px;
    transition: background 0.2s ease-in-out;

    @include typo-navigation;
    @include get-outer-paddings;

    @include mq(m) {
      flex-direction: row;
      padding-top: 45px;
    }
  }

  &__navigation,
  &__back-link {
    flex-grow: 2;
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    justify-self: flex-end;
    transition: opacity 0.2s ease-in-out;
  }

  &__navigation {
    align-items: flex-start;

    @include mq(m) {
      align-items: flex-end;
    }
  }

  &__topline {
    margin: 0;
    font-weight: 400;
    color: var(--color-gold);
    transition: color 0.2s ease-in-out;
  }

  ul {
    display: block;
    margin-top: var(--space-s);
    transition: opacity 0.2s ease-in-out;

    li {
      display: inline-block;
    }

    li:not(:last-of-type):after {
      content: "—";
      margin: 0 0.5rem;
    }
  }

  a {
    border-bottom: var(--border-size) solid transparent;

    &.is-active {
      border-color: var(--color-yellow);
      pointer-events: none;
    }
  }

  &__categories {
    margin-bottom: var(--space-m);
  }

  &__logo {
    position: relative;
    z-index: 1;
    flex-shrink: 0;
    margin-bottom: var(--space-l);
    transition: all 0.2s ease-in-out;

    @include mq(m) {
      margin-bottom: 0;
    }

    a {
      display: block;
    }

    svg {
      width: 100%;
      max-width: 150px;
      height: auto;

      @include mq(m) {
        max-width: 300px;
      }
    }
  }

  &__meta {
    position: fixed;
    display: none;
    top: var(--border-size);
    left: 0;
    width: 100%;
    padding: 5px var(--space-s);

    background: transparent;
    z-index: 99;
    justify-content: flex-end;
    transition: background 0.4s ease-in-out;
  }

  &__burger {
    position: relative;
    background: none;
    border: none;
    outline: none;
    margin: -5px 0;
    appearance: none;
    cursor: pointer;
    width: 42px;
    height: 42px;
    transition: all 0.3s ease-in-out;

    span {
      position: absolute;
      top: 8px;
      left: 0;
      width: 100%;
      height: 2px;
      background: var(--color-darkgrey);
      transition: all 0.3s ease-in-out;

      &:nth-of-type(2) {
        top: 20px;
      }

      &:nth-of-type(3) {
        top: 32px;
      }
    }
  }

  &[data-type="project"] {
    .header__wrapper {
      min-height: 160px;
      z-index: 0;
    }

    .header__logo {
      position: fixed;

      svg {
        max-width: 120px;

        @include mq(m) {
          max-width: 150px;
        }
      }
    }
  }

  &[data-type="collection"] {
    .header__wrapper {
      position: fixed;
    }

    .header__spacer {
      height: 365px;

      @include mq(m) {
        height: 300px;
      }
    }
  }

  &[data-has-intro="true"] {
    ul {
      opacity: 0;
    }

    &.intro-hidden {
      .header__topline {
        color: var(--color-gold) !important;
      }

      .header__logo {
        color: var(--color-black) !important;
      }

      &:not(.is-scrolled) ul {
        opacity: 1;
      }
    }
  }

  &.is-scrolled {
    .header__logo {
      opacity: 0.2;
    }

    .header__navigation {
      opacity: 0;
    }
  }

  &[data-type="collection"].is-scrolled {
    .header__meta {
      display: flex;
    }
  }

  &.is-up {
    .header__meta {
      background: #d6d6d6ed;
    }
  }

  &.navigation-open {
    .header__wrapper {
      background: #d6d6d6ed;
      z-index: 97;
    }

    .header__meta {
      background: transparent;
    }

    .header__meta {
      display: flex;
    }

    .header__navigation,
    ul,
    .header__logo {
      opacity: 1 !important;
    }

    .header__burger {
      span {
        transform: rotate(45deg) translate(9px, 9px);
        transform-origin: center;

        &:nth-of-type(2) {
          transform: translateX(-6px);
          opacity: 0;
        }

        &:nth-of-type(3) {
          transform: rotate(-45deg) translate(8px, -8px);
        }
      }
    }
  }

  body.footer-visible & .header__logo {
    opacity: 0;
  }
}
