$var-notice-max-width: 600px;

@import "~klaro/src/scss/klaro.scss";

:root {
  --green1: #6b6538;
}

.klaro {
  .cm-btn {
    padding: space(xxs) space(xs) !important;
  }

  .cn-buttons {
    margin-top: -5px !important;
  }
}
