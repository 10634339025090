.input {
  & > span {
    display: none;
  }

  input {
    @include typo-input;
    width: 100%;
    background: none;
    border: none;
    color: var(--color-black);
    padding: var(--space-xs);
    border: 3px solid var(--color-mediumgrey);
    border-radius: 5px;
    margin-bottom: var(--space-xs);
    outline: none;

    &::placeholder {
      color: var(--color-darkgrey);
    }

    &:focus {
      border-color: var(--color-brown);
    }
  }

  &.error input {
    border-color: red;
  }
}
