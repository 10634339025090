/*
Theme Name: Ilan Hamra Kirby CMS
Author: Johannes Eslage
Author URI: https://brncjr.de/
Version: 1.0
*/
@import "sass-mq";

@import "~sanitize.css";

@import "./mixins.scss";
@import "./typography.scss";
@import "./settings.scss";
@import "./globals.scss";

@import "./components/input.scss";
@import "./components/textarea.scss";
@import "./components/button.scss";

@import "./snippets/cookie-layer.scss";
@import "./snippets/header.scss";
@import "./snippets/footer.scss";
@import "./snippets/intro-video.scss";
@import "./snippets/project-meta.scss";
@import "./snippets/next-project.scss";
@import "./snippets/collection-list.scss";
@import "./snippets/grid.scss";
@import "./snippets/scroll-blocks.scss";
@import "./snippets/contact-form.scss";
