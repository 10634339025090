.project-meta {
  padding: var(--space-l) 0;
  @include get-grid;
  @include get-outer-paddings;
  line-height: 0.9 !important;

  h1 {
    @include typo-headline;
    font-weight: 200;
    text-align: center;
    margin: 0;
    grid-column: 1 / span 6;
    grid-row: 1;

    @include mq(m) {
      grid-column: 4 / span 6;
    }
  }

  &__description {
    grid-column: 1 / span 6;
    grid-row: 2;

    @include mq(m) {
      grid-column: 3 / span 8;
    }

    p {
      @include typo-text;
      margin: 0;
    }

    p + p {
      margin: 0 0 var(--space-s);
    }
  }

  &__prev,
  &__next {
    @include typo-headline;
    font-weight: 200;
    opacity: 0.5;
  }

  &__prev {
    grid-column: 1 / span 3;
    grid-row: 3;

    @include mq(m) {
      grid-row: 1;
    }
  }

  &__next {
    justify-self: flex-end;
    grid-column: 4 / span 3;
    grid-row: 3;
    text-align: right !important;

    @include mq(m) {
      grid-row: 1;
      grid-column: 10 / span 3;
    }
  }

}
